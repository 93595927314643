import React, { Component } from "react"

/* Helpers */
import { withRouter } from '../helpers'

/* Router */
import { NavLink } from "react-router-dom"


/*
    Widget Menu
*/
class Menu extends Component {

    render = () => {
        return (
            <div className="menu">

                <div className="menu-logo">
                    <img src="/pictures/icon.png" alt="Icon" />
                    <h2>ECOMARKET</h2>
                </div>

                <NavLink to="/" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/dashboard.png" alt="Dashboard" />
                    <div className="menu-name">Dashboard</div>
                </NavLink>

                <div className="menu-space">Магазин</div>

                <NavLink to="/order" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/orders.png" alt="Orders" className="bigger" />
                    <div className="menu-name">Заказы</div>
                </NavLink>

                <NavLink to="/category" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/category.png" alt="Category" />
                    <div className="menu-name">Категории</div>
                </NavLink>

                <NavLink to="/product" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/product.png" alt="Product" className="bigger" />
                    <div className="menu-name">Продукты</div>
                </NavLink>

                <NavLink to="/review" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/review.png" alt="Review" />
                    <div className="menu-name">Отзывы</div>
                </NavLink>

                <NavLink to="/user" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/user.png" alt="User" className="bigger" />
                    <div className="menu-name">Пользователи</div>
                </NavLink>

                <div className="menu-space">Склад</div>

                <NavLink to="/city" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/pin.png" alt="Pin" className="bigger" />
                    <div className="menu-name">Города</div>
                </NavLink>

                <NavLink to="/shop" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/store.png" alt="Store" className="bigger" />
                    <div className="menu-name">Магазины</div>
                </NavLink>

                <div className="menu-space">Доступы</div>

                <NavLink to="/manager" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/crown.png" alt="Crown" className="bigger" />
                    <div className="menu-name">Администраторы</div>
                </NavLink>

            </div>
        )
    }
}

export default withRouter(Menu)